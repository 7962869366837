import React from "react";
import "./Chip.css";
export interface IProps {
    value: string
}



let Chip = (props: IProps) => {
    return (<div className="chip">{props.value}</div>)
}
export default Chip;