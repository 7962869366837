export const fetchWords = () => {
  return new Promise(function (resolve, reject) {
    fetch('https://api.github.com/gists/6a822bdbe95eec1f3912e047eb32443b')
      .then(response => response.json())
      .then((gist) => {
        const all_words = JSON.parse(gist.files["words.json"].content);
        resolve(all_words);
      });
  });
}
