import React, { useEffect } from "react";
import { RouteComponentProps, useParams } from 'react-router-dom';

import { Searchbar, NewWordCard, TranslationCard } from "../../components/index";
import { Word } from "../../types/index";
import "./SearchresultWindow.css";


export interface IProps extends RouteComponentProps {
    search_term: string,
    search_result: Array<Word>,
    add_new_word?: Boolean,
    onSave: (newword: Word) => Boolean,
    onSearch: (search_term: string) => void
}



let SearchresultWindow = (props: IProps) => {
    const { search_term: new_search_term } = useParams();
    const maxSearchtermLength = 15;
    const trimmedSearchterm = (new_search_term!.length > maxSearchtermLength) ?
        new_search_term!.substring(0, maxSearchtermLength - 3) + "..." :
        new_search_term;

    const onSearchEvent = (search_input: string) => {
        props.history.push('/search/' + search_input);
    }

    useEffect(() => {
        if (props.search_term !== new_search_term) {
            props.onSearch(new_search_term!);
        }
    })

    return (
        <div className="searchresultwindow">
            <main>
                <Searchbar header={new_search_term} onSearch={onSearchEvent} />
                <section className="searchresultwindow__results">
                    {(props.add_new_word || props.search_result.length === 0) ?
                        (<NewWordCard
                            key="newwordcard"
                            default_primary={trimmedSearchterm}
                            onSave={props.onSave} />) :
                        ""}

                    {props.search_result.map((result_item) => {
                        return (
                            <TranslationCard
                                key={result_item.language_primary}
                                language_primary={result_item.language_primary}
                                language_secondary={result_item.language_secondary}
                                description={result_item.description} />
                        )
                    })}
                </section>
            </main>
        </div>
    )
}
export default SearchresultWindow;