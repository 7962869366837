import React, { FormEvent } from "react";
import "./Searchbar.css";
export interface IProps {
    onSearch: (input: string) => void,
    header?: String
}



let Searchbar = (props: IProps) => {

    let onSearchEvent = (evt:React.FormEvent<HTMLFormElement>)=>{
        evt.preventDefault();
        let search_value = evt.currentTarget["search_input"].value;
        props.onSearch(search_value)
    }


    return (<form className={"searchbar" + (props.header == null ? "":" searchbar--header")} onSubmit={onSearchEvent}>
        {props.header == null ? "": (<span className="searchbar__title"> {props.header}</span>)}
        <input className="searchbar__input" type="text" name="search_input" placeholder="Suche..."/>
        <input className="searchbar__submit" type="submit" title="Suchen" value=""/>
    </form>)
}
export default Searchbar;