import React from "react";
import { RouteComponentProps } from 'react-router-dom';
import { Searchbar, ChipList } from "../../components/index";
import "./SearchWindow.css";



export interface IProps extends RouteComponentProps<any> {
    chip_values: Array<string>,
}



let SearchWindow = (props: IProps) => {
    const onSearchEvent = (search_input: string) => {
        props.history.push('/search/' + search_input)
    }

    return (
        <div className="searchwindow">
            <main>
                <Searchbar onSearch={onSearchEvent} />
                <ChipList values={props.chip_values} />
            </main>
        </div>
    )
}


export default SearchWindow
