import React from "react";
import "./TranslationCard.css";

export interface IProps {
    language_primary: string,
    language_secondary: string,
    description?: string
}



let TranslationCard = (props: IProps) => {
    return (<section className="translationcard">
        <div className="translationcard__primary">{props.language_primary}</div>
        <div className="translationcard__secondary">{props.language_secondary}</div>
        {(props.description) ?
            (<div className="translationcard__description">{props.description}</div>) :
            ""}
    </section>)
}
export default TranslationCard;