import * as React from "react";
import { render } from "react-dom";
import { Provider } from 'react-redux';
import { createStore,applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga'
import { searchReducer } from './reducers/index';
// import { searchStore } from './reducers/store';
// import { StoreState } from './types/index';
import searchSaga from './sagas/searchSaga';

import * as serviceWorker from './serviceWorker';
import App from "./App";
import { composeWithDevTools } from 'redux-devtools-extension';

import "./index.css"; 
import { searchStore } from "./reducers/store";

const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const sagaMiddleware = createSagaMiddleware()

const store = createStore<any, any, any, any>(
  searchReducer,
  searchStore,
  composeEnhancers(
    applyMiddleware(...[sagaMiddleware]),
  )
)

sagaMiddleware.run(searchSaga)

const Root = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

render(<Root />, document.getElementById("root"));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
