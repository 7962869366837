
import { StoreState } from '../types/index';
import { SEARCH_FOR_WORD, SET_ALL_WORDS } from '../constants/index';
import searchManager from '../utils/SearchManager';

export function searchReducer(state: StoreState, action: any): StoreState {
  switch (action.type) {
    case SEARCH_FOR_WORD:
      searchManager.list = state.all_words;
      const results = searchManager.search(action.search_term);
      return { ...state, search_term: action.search_term, search_result: results };

    case SET_ALL_WORDS:
      return { ...state, all_words: action.payload.all_words };
    default:
      return state;
  }
}