import { Word } from '../types/index';
import Fuse from 'fuse.js';


export interface ISearchManager {
    list: Array<Word>,
    search: (search_term: string) => Array<Word>
}

const searchManager: ISearchManager = {
    list: [],
    search: (search_term: string) => {
        var options = {
            shouldSort: true,
            threshold: 0.5,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
                "language_primary",
                "language_secondary",
                "description"
            ]
        };
        var fuse = new Fuse(searchManager.list, options); // "list" is the item array
        var result = fuse.search(search_term);
        return result;
    }

}

export default searchManager;