import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import * as actions from './actions/index';
import { StoreState, Word } from './types/index';
import { connect } from 'react-redux';

import SearchresultWindow from "./containers/SearchresultWindow";
import SearchWindow from "./containers/SearchWindow";

import './App.css';


export interface IProps {
  fetchAllWords?: () => void,
  children?: any
}


const App: React.FC = (props: IProps) => {

  const [initalized, setInitalized] = useState(false);

  useEffect(() => {
    if (!initalized) {
      props.fetchAllWords!();
      setInitalized(true)
    }
  })

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={SearchWindow} />
          <Route path="/search/:search_term"
            render={(props) => <SearchresultWindow {...props} add_new_word={true} onSave={(newword: Word) => { console.log("Saved", newword); return true; }} />}
          />
        </Switch>
      </Router>
    </div >
  );
}

export function mapStateToProps(store: StoreState) {
  return {
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {
    fetchAllWords: () => dispatch(actions.fetchAllWords())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
