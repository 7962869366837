
import React, { useState } from "react";
import { Word } from "../../types/index"
import "./NewWordCard.css";

export interface IProps {
    default_primary?: string,
    default_secondary?: string,
    onSave: (translation: Word) => Boolean

}

let NewWordCard = (props: IProps) => {

    const [collapsed, setCollapsed] = useState(true);

    let onSave = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        if (collapsed) {
            setCollapsed(false);
        } else {
            let newword: Word = {
                language_primary: evt.currentTarget["newwordcard__primary"].value,
                language_secondary: evt.currentTarget["newwordcard__secondary"].value,
                description: evt.currentTarget["newwordcard__description"].value,

            };
            // console.log("saved '" + newword.language_primary + "'");
            props.onSave(newword);
        }
    }

    return (
        <form className={"newwordcard" + ((collapsed) ? " newwordcard--collapsed" : "")} onSubmit={onSave}>
            <div className="newwordcard__primary">
                <span className="newwordcard__primary_icon"></span>
                <input className="newwordcard__primary_input" name="newwordcard__primary" type="text" defaultValue={props.default_primary} />
            </div>
            <div className="newwordcard__secondary">
                <span className="newwordcard__secondary_icon"></span>
                <input className="newwordcard__secondary_input" name="newwordcard__secondary" type="text" defaultValue={props.default_secondary} />
            </div>
            <textarea className="newwordcard__description_input" name="newwordcard__description" id="" cols={30} rows={10}></textarea>
            <input className="newwordcard__add" type="submit" value={(collapsed) ? ("'" + props.default_primary + "' hinzufügen") : "Hinzufügen"} />
        </form>)
}
export default NewWordCard;