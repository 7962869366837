import React from "react";
import "./ChipList.css";
import { Chip } from "../index";
export interface IProps {
    values: Array<string>
}



let ChipList = (props: IProps) => {
    return (<div className="chiplist">
        {props.values.map((value) => {
            return (<Chip key={value} value={value} />);
        })}
    </div>)
}
export default ChipList;