import { call, put, takeLatest } from 'redux-saga/effects';
import { FetchWordsAction} from "../actions/index";
import {fetchWords} from "./api";
import * as constants from '../constants';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchAllWords(action:FetchWordsAction) {
   try {
      var all_words = yield call(fetchWords)
      yield put({type: constants.SET_ALL_WORDS, payload:{all_words: all_words}});
   } catch (e) {
      yield console.error("All words fetching failed")
   }
}

function* searchSaga() {
  yield takeLatest(constants.FETCH_ALL_WORDS, fetchAllWords);
}

export default searchSaga;