import { SearchresultWindow } from '../pages/';
import * as actions from '../actions/';
import { StoreState } from '../types/index';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

export function mapStateToProps({ search_term, search_result }: StoreState) {
  return {
    search_term,
    search_result
  };
}

export function mapDispatchToProps(dispatch: Dispatch<actions.SearchAction>) {
  return {
    onSearch: (search_term: string) => dispatch(actions.searchForWord(search_term)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchresultWindow);
