
import * as constants from '../constants';

export interface SearchForWord {
    type: constants.SEARCH_FOR_WORD;
    search_term: string;
}
export type SearchAction = SearchForWord;


export interface FetchAllWords {
    type: constants.FETCH_ALL_WORDS;
}
export type FetchWordsAction = FetchAllWords;


export function searchForWord(search_term: string): SearchForWord {
    return {
        type: constants.SEARCH_FOR_WORD,
        search_term: search_term
    };
}

export function fetchAllWords(): FetchAllWords {
    return {
        type: constants.FETCH_ALL_WORDS,
    };
}
