import { SearchWindow } from '../pages/';
import { StoreState } from '../types/index';
import { connect } from 'react-redux';
import { Word } from '../types';

export function mapStateToProps({ all_words }: StoreState) {

  return {
    chip_values: shuffle(all_words).slice(0, 5).map((item: Word) => item.language_secondary)
  };
}

export function mapDispatchToProps(dispatch: any) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchWindow);

function shuffle(a: Array<any>) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}